import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Badge, Spinner } from "reactstrap"
import { Link } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { debounce, map, range } from "lodash"
import PropTypes from "prop-types"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider from "react-bootstrap-table2-toolkit"

//actions

import "../../../../assets/scss/datatables.scss"
import MyPagination from "components/Common/MyPagination"
import { deleteWorkOrder, getWorkOrders } from "store/actions"
import DeleteModal from "components/Common/DeleteModal"

const OrderHistory = ({ orderStatus, title }) => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [searchText, setSearchText] = useState("")
  const [searchActive, setSearchActive] = useState(true)
  const [searchDelay, setSearchDelay] = useState(600)
  const [deleteIsOpen, setDeleteIsOpen] = useState(false)
  const { orders, loading } = useSelector(state => ({
    orders: state.WorkOrders.workOrders,
    loading: state.WorkOrders.loading,
  }))


  //page
  const totalPages = Math.ceil(orders?.count / 10)
  const pages = range(1, totalPages + 1)

  const pageSend = () => {
    if (page >= pages.length) {
      return pages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  // const search = (searchText)=>{
  //   dispatch(getWorkOrders(searchText, pageSend()))
  // }

  // const debouncedSearch = debounce(search(searchText), 500); // Wait 500ms after last key press


  const handleDeleteEvent = (id) => {
    dispatch(deleteWorkOrder(id, history))
    setDeleteIsOpen(false)
    history.push("/workorders?removed")
  }
  const handleDelete = () => {
    setDeleteIsOpen(true)
  }

  useEffect(() => {
    dispatch(getWorkOrders(searchText, pageSend()))

    console.log(orders);

  }, [dispatch, page, searchText])


  useEffect(() => {
    setSearchActive(true)

  }, [orders, searchText])

  const columns = [
    {
      dataField: "auto_id",
      text: "Order Id",
    },
    {
      dataField: "dealer_name",
      text: "Dealer",
      sort: true,
    },
    {
      dataField: "customer_name",
      text: "Customer",
      sort: true,
    },
    {
      dataField: "order_creator",
      text: "Creator",
      sort: true,
    },
    // {
    //   dataField: "bill_amount",
    //   text: "Total Amount",
    // },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "action",
      text: "Action",
    },
  ]

  const Status = status => {
    if (status == "Pending") {
      return "info"
    }
    if (status == "Approved") {
      return "success"
    }
    if (status == "Canceled") {
      return "danger"
    }
    if (status == "Shipped") {
      return "success"
    }
    if (status == "Delivered") {
      return "success"
    }
    if (status == "Started") {
      return "warning"
    }
  }

  const ordersData = map(orders?.results, (item, index) => ({
    ...item,
    key: index,
    status: (
      <div
        className="d-flex"
        style={{
          maxWidth: "120px",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Badge
          className={"font-size-12 badge-soft-" + `${Status(item.order_status)}`}
          pill
        >
          {item.order_status}
        </Badge>
      </div>
    ),
    action: (
      <>
        {/* <Link
          type="button"
          className="btn btn-sm btn-info btn-rounded"
          to={`/workorders/${item?.id}`}
        >
          View
        </Link> */}
        <Link
          type="button"
          className="btn btn-sm btn-secondary btn-rounded ms-1"
          to={`/workorders/update/${item?.id}`}
        >
          PDF view
        </Link>
        <span>
          <i
            style={{ cursor: "pointer" }}
            className="bx bx-trash text-danger px-4"
            onClick={() => handleDelete(item?.id)}
          ></i>
        </span>

        {/* <div
          type="button"
          className="btn btn-danger d-flex display-none ms-2"
          style={{
            alignItems: "center",
            width: "fit-content",
            border: "1px solid #cccc",
          }}
          onClick={handleDelete}
        >
          Delete
        </div> */}
        <DeleteModal
          show={deleteIsOpen}
          onCloseClick={() => setDeleteIsOpen(false)}
          onDeleteClick={() => handleDeleteEvent(item?.id)}
        />
      </>

    ),
  }))

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ]

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  }

  const setNewSearchDelay = (e) => {
    setSearchActive(false)
    setSearchText(e.target.value)
    setSearchDelay(600)
  }
  const debouncedSearch = debounce(setNewSearchDelay, searchDelay)
  const handleSearch = e => {
    e.preventDefault()
    debouncedSearch(e)
  }


  return (
    <React.Fragment>
      <Row>

        <Col className="col-12">
          <CardBody>
            <ToolkitProvider
              keyField="id"
              columns={columns}
              data={ordersData}
              search
            >
              {toolkitProps => (
                <React.Fragment>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <form
                            className="app-search d-lg-block"
                            onChange={e => handleSearch(e)}
                          >
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Search..."
                                defaultValue={searchText}
                                readOnly={!searchActive}
                              />
                              <span className="bx bx-search-alt" />
                            </div>
                          </form>{" "}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {loading ? (
                    <Spinner color="secondary" className="d-block m-auto" />
                  ) : (
                    ordersData.length > 0 ? (
                      <>
                        <Row>
                          <Col xl="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                keyField={"id"}
                                responsive
                                bordered={false}
                                striped={false}
                                defaultSorted={defaultSorted}
                                selectRow={selectRow}
                                classes={"table align-middle table-nowrap"}
                                headerWrapperClasses={"thead-light"}
                                {...toolkitProps.baseProps}
                              />
                            </div>
                          </Col>
                        </Row>
                        <MyPagination
                          pages={pages}
                          clcickedPage={page}
                          onNunClick={item => setPage(item)}
                          onNextClick={() => setPage(page + 1)}
                          onPrevClick={() => setPage(page - 1)}
                          onFastNextClick={() => setPage(pages.length)}
                          onFastPrevClick={() => setPage(1)}
                          apiPage={pageSend}
                        />
                      </>) : (
                      <p className="text-warning p-4">No {title}</p>
                    )
                  )}
                </React.Fragment>
              )}
            </ToolkitProvider>
          </CardBody>
        </Col>

      </Row>
    </React.Fragment>
  )
}

export default OrderHistory

OrderHistory.propTypes = {
  orderStatus: PropTypes.string,
  title: PropTypes.string,
}
